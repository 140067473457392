import VCard from './Components/VCard/VCard';

function App() {
  return (
    <div>
      <VCard />
    </div>
  );
}

export default App;
