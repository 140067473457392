import React from 'react';
import logo from '../../Assets/logo-new.png';
import mblIcon from '../../Assets/Fonts/mobile.png';
import phoneIcon from '../../Assets/Fonts/phone.png';
import webIcon from '../../Assets/Fonts/web.png';
import emailIcon from '../../Assets/Fonts/mail.png';
import person from '../../Assets/image.png';
import './VCard.css';

const VCard = () => {
    return (
        <div className='vCard'>
            <div className="left">
                <div className="logo">
                    <img src={logo} alt="ALUX REALITY" />
                </div>

                <div className="vcData">
                    <h1>ZAHEER KHANZADA</h1>
                    <h2>MBA,CBS,SRS,PSA,CIRE,GRI,CTP,CEM,CET</h2>
                    <h3>REAL ESTAE BROKER</h3>
                </div>

                <div className="cta">
                    <a href="tel:5164975674‬"className="ctaBtnBg"><button className='ctaBtn'><img className='icon' src={mblIcon} alt="mobile" />CALL MOBILE</button></a>

                    <a href="tel:5165870000" className="ctaBtnBg"><button className='ctaBtn'><img className='icon' src={phoneIcon} alt="mobile" />CALL OFFICE</button></a>

                    <a href="http://www.aluxrealty.com/" target="_blank" className="ctaBtnBg"><button className='ctaBtn'><img className='icon' src={webIcon} alt="mobile" />VISIT WEBSITE</button></a>

                    <a href="mailto:zaheer.khanzada@aluxrealty.com" className="ctaBtnBg"><button className='ctaBtn'><img className='icon' src={emailIcon} alt="mobile" />SEND EMAIL</button></a>

                </div>

                <div className="person">
                    <img className='personImg' src={person} alt="Zaheer" />
                </div>
            </div>

            <div className="right">

            </div>


        </div>
    )
}

export default VCard